export const BRAND = 'brand';
export const DEVICE_ID = 'deviceId';
export const LANGUAGE_PACK = 'LANGUAGE_PACK';
export const PRODUCT_ID = 'PRODUCT_ID';
export const DEFAULT_BRAND = 'hnonline';
export const LOCALE = 'selected-locale';
export const USER = 'user';
export const LOGIN_URL = {
    'prod': 'https://iam.pearson.com/auth/XUI/?realm=/pearson&authIndexType=service&authIndexValue=Login&goto=',
    'nonprod': 'https://iam-stage.pearson.com/auth/XUI/?realm=/pearson&authIndexType=service&authIndexValue=Login&goto='
};