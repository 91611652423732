import axios from 'axios';
import { LOGIN_URL } from './../utils/constants';

export default class AuthenticationService {

  getConfig = () => {
    return axios.get('/api/config');
  }

  getLoginUrl = () => {
    let tokenUrl = LOGIN_URL.prod;
    if (window.location.origin.indexOf('stg') > -1) {
      tokenUrl = LOGIN_URL.nonprod;
    }
    return tokenUrl;
  }

  redirect = (url) => {
    console.log('NOT AUTHENTICATED SO RELOGIN');
    console.log('redirecting to: ', url);
    setTimeout(() => {
      window.location.href = url;
    }, 1000)
  }

  logout = () => {
    return axios.post('/api/token/logout', {});
  }

  checkTokenValidity = () => {
    return axios.get('/api/token/validity');
  }

  getLineOfBusiness = () => {
    return axios.get('/api/user/lineOfBusiness');
  }
}
